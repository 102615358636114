<template>
  <div class="about">
    <h1>This is an about page</h1>
    <button @click="test">test</button>
  </div>
</template>

<script>
import { productsRef, productsBkpRef } from './../services/Database'

export default {
  methods: {    
    async test() {
        let snaps = await productsRef.get()
        let docs = snaps.docs

				for(let item of docs) {
          
          // await productsBkpRef.doc(item.id).set(item.data())
          await productsRef.doc(item.id)
          .update({ 
            price: Array.from({ length: 7 }, (_, i) => item.data().price[0])
            // price: [
            //   'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'
            // ] 
          })
          console.log(item.id, ' updated')

					// try {						
					// 	let snap = await db.doc("products/"+item.id).get()				  
					// 	itemData = snap.data()
					// 	if (!itemData.available) {
					// 		let index = this.cart.findIndex((x) => x.id == item.id);
					// 		this.itemsUnavailable.push(item.name);
					// 		this.cart.splice(index, 1);
					// 	}
					// } catch (error) {
					// 	return Promise.reject(error)	
					// }						
				}
    }
  }
}
</script>